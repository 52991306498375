@import "../../variables";

.App.skills .layout-inner {
  background: $gradient-bg;
}

.App.skills .main {
  overflow: auto;
}

.skills-component {
  .points-available {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 50px;
      margin-right: 20px;
    }

    .points-no {
      font-weight: 900;
      font-size: 36px;
      line-height: 58px;
      color: $color-purple-10;
      margin-right: 20px;
      display: block;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding-top: 5px;
    }
  }

  .box-skill {
    padding: 22px 17px 17px 17px;
    margin-bottom: 20px;

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 20px;
    }

    .levels {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 20px;

      > img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding-top: 16px;
        z-index: 1;
      }

      .skill-level {
        width: 47%;

        > p {
          font-size: 14px;
          line-height: 23px;
          text-align: center;
          margin-bottom: 5px;
        }

        &.next {
          > p {
            color: $color-purple-10;
          }

          .box-description {
            border: 2px solid $color-purple-10;
            padding: 13px;
          }
        }

        .box-description {
          background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 54.17%, #DEECEC 100%);
          background-color: #ffffff;
          filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
          border-radius: 10px;
          padding: 15px;
          display: block;
          box-sizing: border-box;

          p {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }

    .btn {
      width: 295px;
      max-width: 100%;
      height: 83px;
      background-image: url('./img/bg-button-purple.png');
      background-size: 100% 100%;
    }
  }
}

@media (max-height: 750px) {
  .skills-component .box-skill .btn {
    padding-top: 17px;
  }
}