// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-text: #1E1D52;
$color-blue-1: #44B0F0;
$color-blue-2: #20ABDB;
$color-blue-3: #20ADDE;
$color-blue-4: #DFF2F9;
$color-blue-5: #1482A9;
$color-blue-6: #2ebaec;
$color-blue-7: #1c8fbf;
$color-blue-8: #1C7DB4;
$color-blue-9: #2EA4ED;
$color-blue-10: #7fd1f2;
$color-blue-11: #127FDB;
$color-blue-12: #B4D3DD;
$color-blue-13: #2B98F0;
$color-blue-14: #0A76D6;
$color-gray-1: #DEECEC;
$color-gray-2: #ABADAA;
$color-gray-3: #CDC8C8;
$color-gray-4: #D9D9D9;
$color-purple-1: #3D3492;
$color-purple-2: #975275;
$color-purple-3: #DF6B89;
$color-purple-4: #403880;
$color-purple-5: #867DC1;
$color-purple-6: #382F80;
$color-purple-7: #BC29E5;
$color-purple-8: #9834E9;
$color-purple-9: #B11248;
$color-purple-10: #9D33E7;
$color-purple-11: #382F7F;
$color-green-1: #75C553;
$color-green-2: #528639;
$color-green-3: #96CD8C;
$color-green-4: #96B534;
$color-green-5: #CEE453;
$color-green-6: #70A148;
$color-green-7: #1CB4A1;
$color-alert: #EF095C;
$color-alert-2: #D30D0D;
$color-orange-1: #E0A91B;
$color-orange-2: #E0621B;
$color-yellow-1: #FFBE16;


$gradient-bg: linear-gradient(180deg, rgba($color-white, 0) 0%, $color-blue-4 100%), $color-white;