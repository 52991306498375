@import "../../variables";

.App.store .layout-inner {
  background: $gradient-bg;
}

.App.store .main {
  overflow: auto;
}

.store-component {
  padding-top: 9px;

  .tabs-nav {
    li {
      &.selected-tab {
        .box-wave {
          background: $color-blue-3;
        }
      }
    }
  }

  .banners-tab {
    .banner-img {
      display: block;
      margin-bottom: 15px;
      text-align: center;

      img {
        border-radius: 10px;
      }
    }
  }

  .tickets-tab {
    > p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
  }

  .tickets-box {
    margin-bottom: 20px;

    &.popular {
      .title-ticket-box {
        background-color: $color-orange-1;
      }
    }

    &.best-value {
      .title-ticket-box {
        background-color: $color-orange-2;
      }
    }

    &.with-title {
      .tickets-box-inner {
        padding-top: 6px;
      }
    }

    .tickets-box-inner {
      padding: 10px 17px 10px 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }

    .big-font {
      font-weight: 800;
      font-size: 36px;
      line-height: 31px;

      span {
        font-size: 24px;
        line-height: 14px;
        margin-left: 5px;
        display: inline-block;
      }
    }

    .tickets-no {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 107px;

      p {
        color: $color-purple-11;

        span {
          font-size: 36px;
          line-height: 31px;
          color: $color-purple-11;
        }
      }

      img {
        margin-left: 5px;
        padding-top: 7px;
      }
    }

    .ticket-price {
      display: flex;
      align-items: center;

      .cost {
        padding-right: 18px;
        text-align: right;

        p {
          font-weight: 700;
        }

        .big-font {
          margin-bottom: 6px;
        }

        .ticket-cost {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }
  }

  .buy-btn {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .store-component {
    .tickets-box {
      .tickets-box-inner {
        padding: 17px 10px;
      }

      .big-font {
        font-size: 32px;
        line-height: 28px;
      }

      .tickets-no {
        width: 90px;

        img {
          width: 30px;
        }
      }

      .ticket-price {
        .cost {
          padding-right: 5px;
        }
      }
    }
  }
}