@import "../../variables";

.game-instructions {
  display: flex;
  flex-direction: column;
  height: 100%;

  .instructions-image {
    padding-left: 25px;
    padding-right: 46px;
    margin-left: auto;
    margin-right: auto;

    img {
      max-height: 330px;
    }
  }

  .instructions-globy {
    position: relative;
    margin-top: -115px;

    .instructions-message {
      margin-left: auto;
      margin-right: 40px;
      padding: 32px 25px 20px 20px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 54.17%, rgba(222, 236, 236, 0.8) 100%);
      background-color: rgba($color-white, 0.8);
      border-radius: 42px;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      filter: drop-shadow(0px 10px 20px rgba($color-black, 0.3));
      width: 317px;
      max-width: 84.53%;
      position: relative;
      z-index: 2;
      box-sizing: border-box;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        margin-top: 0;
        left: 89px;
        width: 41px;
        height: 24px;
        background-image: url("../home/img/arrow-down.png");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
        opacity: 0.87;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
      }
    }

    .globy {
      display: block;
      max-width: 228px;
      margin-left: auto;
      margin-right: -28px;
      margin-top: -45px;
    }
  }

  .cta {
    margin-top: auto;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (max-height: 700px) {
  .game-instructions {
    .instructions-image {
      img {
        max-height: 280px;
      }
    }

    .instructions-globy {
      .globy {
        max-width: 200px;
      }
    }
  }
}

@media (max-height: 650px) {
  .game-instructions {
    .instructions-globy {
      .instructions-message {
        padding: 15px 20px 10px 20px;
      }
    }
  }
}

@media (max-height: 570px) {
  .game-instructions {
    .instructions-image {
      img {
        max-height: 230px;
      }
    }

    .instructions-globy {
      margin-top: -100px;

      .globy {
        max-width: 160px;
        margin-top: -30px;
      }

      .instructions-message {
        padding: 15px 20px 10px 20px;

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}