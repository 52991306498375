@import "../../variables";

.App.leaderboard .layout-inner {
  background: $gradient-bg;
}

.App.leaderboard .main {
  overflow: auto;
}

.leaderboard-component {
  padding-bottom: 146px;

  .box-shadow {
    padding: 13px;
    margin-bottom: 10px;
  }

  .my-position {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba($color-blue-6, 0.9) 0%, rgba($color-blue-7, 0.9) 100%);
    border-radius: 20px 20px 0 0;
    padding: 10px 20px 20px 13px;
    z-index: 3;

    > p {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      text-transform: uppercase;
      text-align: center;
      color: $color-white;
      margin-bottom: 10px;
    }

    .box-shadow {
      margin: 0;
    }
  }

  .user {
    display: flex;
    align-items: center;
  }

  .avatar-user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: 27px;
      background-image: url('./img/wave-avatar.svg');
    }
  }

  .info-user {
    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
      line-height: 23px;
      margin-top: -3px;
    }
  }
}