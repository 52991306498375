@import '../../../../variables.scss';

.challenge-instructions,
.bonus-challenge-instructions {
  .layout-inner {
    background-image: url("../../../../resources/img/background-blur.png");
  }
}

.instructions-game {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .info-icon {
    font-weight: 500;
    padding-top: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $color-text;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    box-sizing: border-box;
  }

  .instructions {
    p {
      font-size: 14px;
      line-height: 23px;
    }
  }
}


@media (max-height: 750px) {
  .instructions-game {
    margin-top: 10px;
  }
}