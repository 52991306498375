@import '../../../../../variables';

.connect-words-component {

  .words-list {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    &.can-click {
      pointer-events: auto;
    }

    &.words-template-1 {
      &.case-0-3 {
        .item {
          &:nth-child(2) {
            top: 23%;
          }

          &:nth-child(3) {
            top: 41%;
          }
        }
      }

      &.case-0-2 {
        .item {
          &:nth-child(2) {
            top: 23%;
          }
        }
      }

      &.case-1-3 {
        .item {
          &:nth-child(3) {
            top: 40%;
          }
        }
      }

      &.case-2-0 {
        .item {
          &:nth-child(2) {
            left: 51%;
            top: 38%;
          }
        }
      }

      &.case-2-1 {
        .item {
          &:nth-child(4) {
            top: 78%;
          }
        }
      }

      &.case-3-0 {
        .item {
          &:nth-child(2) {
            top: 43%;
          }

          &:nth-child(3) {
            top: 61%;
          }
        }
      }

      &.case-3-1 {
        .item {
          &:nth-child(3) {
            left: 5%;
            top: 64%;
          }
        }
      }

      .item {

        &:first-child {
          top: 13%;
          left: 10%;
        }

        &:nth-child(2) {
          left: 46%;
          top: 36%;
        }

        &:nth-child(3) {
          left: 12%;
          top: 54%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }

        &:nth-child(4) {
          left: 34%;
          top: 76%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &.words-template-2 {

      &.case-0-3 {
        .item {
          &:nth-child(2) {
          }

          &:nth-child(3) {
            top: 42%;
          }
        }
      }

      &.case-1-0 {
        .item {

          &:nth-child(3) {
            top: 54%;
          }
        }
      }

      &.case-1-2 {
        .item {

          &:nth-child(4) {
            left: 9%;
          }
        }
      }

      .item {
        transition: top, left .2s ease-in-out;

        &:first-child {
          top: 13%;
          left: 45%;
        }

        &:nth-child(2) {
          left: 3%;
          top: 33%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }

        &:nth-child(3) {
          left: 50%;
          top: 53%;
        }

        &:nth-child(4) {
          left: 19%;
          top: 76%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &.words-template-3 {
      &.case-0-3 {
        .item {
          &:nth-child(2) {
            top: 23%;
          }

          &:nth-child(3) {
            top: 41%;
          }
        }
      }

      &.case-0-2 {
        .item {
          &:nth-child(2) {
            top: 23%;
          }
        }
      }

      &.case-1-3 {
        .item {
          &:nth-child(3) {
            top: 40%;
          }
        }
      }

      &.case-2-0 {
        .item {
          &:nth-child(2) {
            left: 51%;
            top: 38%;
          }
        }
      }

      &.case-2-1 {
        .item {
          &:nth-child(4) {
            top: 78%;
          }
        }
      }

      &.case-3-0 {
        .item {
          &:nth-child(2) {
            top: 43%;
          }

          &:nth-child(3) {
            top: 61%;
          }
        }
      }

      &.case-3-1 {
        .item {
          &:nth-child(3) {
            left: 5%;
            top: 64%;
          }
        }
      }

      .item {

        &:first-child {
          top: 16%;
          left: 28%;
        }

        &:nth-child(2) {
          left: 50%;
          top: 36%;
        }

        &:nth-child(3) {
          left: 12%;
          top: 54%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }

        &:nth-child(4) {
          left: 47%;
          top: 71%;

          .words-wrapper {
            .words-inner-wrapper {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .item {
      position: absolute;
      width: 47.62%;
      max-width: 150px;
      //padding-bottom: 23.81%;
      text-align: center;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10+ and Edge */
      user-select: none; /* Standard syntax */

      &.dragging {
        z-index: 2;

        //.words-wrapper {
        //  background-image: url("./img/bg-word-active.png");
        //}
      }

      //&.overlapping {
      //
      //  .words-wrapper {
      //    background-image: url("./img/bg-word-active.png");
      //  }
      //}

      &.active {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          height: 90%;
          width: 90%;
          border: 2px solid $color-blue-3;
          border-radius: 100%;
        }
      }
      
      .words-wrapper {
        //height: 100%;
        width: 100%;
        position: relative;
        border-radius: 100% 100% 0 0;
        //background-image: url("./img/bg-word.png");
        //background-size: 100%;
        //background-repeat: no-repeat;

        .bg-word {
          display: block;
        }
      }

      .words-inner-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      p {
        position: absolute;
        top: 54%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        user-select: none;
        line-height: 1.2;
        color: $color-text;
        font-size: 15px;
      }
    }
  }
}

@media (max-height: 750px) {
  .connect-words-component {
    .words-list {

      &.words-template-1{

        .item {

          &:first-child {
            left: 14%;
          }

          &:nth-child(2) {
            left: 50%;
          }

          &:nth-child(3) {
            left: 16%;
          }

          &:nth-child(4) {
            top: 74%;
            left: 38%;
          }
        }
      }

      &.words-template-2 {

        .item {

          &:first-child {
            top: 12%;
          }

          &:nth-child(2) {
            top: 31%;
          }

          &:nth-child(3) {
            top: 50%;
          }

          &:nth-child(4) {
            top: 72%;
          }
        }
      }

      .item {
        max-width: 120px;

        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-height: 600px) {
  .connect-words-component {
    .words-list {

      &.words-template-1 {

        &.case-1-4 {
          .item {
            &:nth-child(2) {
              top: 18%;
            }

            &:nth-child(3) {
              top: 36%;
            }
          }
        }

        &.case-1-3 {
          .item {
            &:nth-child(2) {
              top: 20%;
            }
          }
        }

        &.case-4-2 {
          .item {
            &:nth-child(3) {
              left: 13%;
              top: 57%;
            }
          }
        }

        .item {

          &:first-child {
            left: 17%;
            top: 8%;
          }

          &:nth-child(2) {
            left: 53%;
            top: 30%;
          }

          &:nth-child(3) {
            left: 16%;
            top: 47%;
          }

          &:nth-child(4) {
            left: 41%;
            top: 69%;
          }
        }
      }

      .item {
        max-width: 100px;

        p {
          font-size: 12px;
        }
      }
    }
  }
}