@import '../../variables.scss';

.bonus-modal {
  
  .modal-info {
    text-align: center;
  }

  .modal-message {
    margin-top: -40px;
  }
}