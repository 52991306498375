@import '../../variables.scss';

.skills-modal {
  .close-modal {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .level {
    margin-top: 15px;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;

    img {
      height: 227px;
    }

    p {
      font-weight: bold;
      font-size: 26px;
      line-height: 43px;
      color: $color-white;
      position: absolute;
      bottom: 7px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .points {
    text-align: center;
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 20px;
  }

  .modal-message {
    font-size: 14px;
    line-height: 23px;
    padding-left: 7px;
    padding-right: 7px;
  }
}