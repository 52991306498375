@import '../../variables.scss';

.four-zero-four-page {
  height: 100%;
  background-image: url("../../resources/img/background-blur.png");
  background-size: cover;

  .content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;

    .btn {
      margin-top: 20px;
    }
  }
}