@import '../../variables.scss';

.form-field {
  .default-input {
    height: 61px;
    width: 100%;
    text-align: center;
    padding: 0 17px;
    box-sizing: border-box;
    appearance: none;
    line-height: 61px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-text;
      font-size: 16px;
      font-weight: 400;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $color-text;
      font-size: 16px;
      font-weight: 400;
    }

    &::-ms-input-placeholder {
      color: $color-text;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .error-input {
    color: $color-alert-2;
    border-color: $color-alert-2;
    box-shadow: inset 0 0 20px rgba(211, 13, 13, 0.2);
  }

  label {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    display: block;
  }

  .validation-message {
    font-size: 14px;
    line-height: 23px;
    color: $color-alert-2;
    margin-top: 10px;
    padding-left: 17px;
    text-align: left;
  }
}