@import '../../variables';

.timer {
  background: rgba($color-white, 0.8) linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, $color-gray-1 100%);
  filter: drop-shadow(0 10px 20px rgba($color-black, 0.3));
  border-radius: 8px;
  width: 103px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;

  &.critical {
    &::before {
      content: "!";
      position: absolute;
      left: 10px;
      font-size: 24px;
      font-weight: 800;
      color: $color-alert;
    }

    &::after {
      content: "!";
      position: absolute;
      right: 10px;
      font-size: 24px;
      font-weight: 800;
      color: $color-alert;
    }

    p {
      color: $color-alert;
    }
  }

  p {
    font-weight: 800;
    font-size: 20px;
    line-height: 32px;
  }
}

@media (max-height: 600px) {
  .timer {
    width: 80px;
    height: 32px;

    p {
      font-size: 16px;
      line-height: 32px;
    }
  }
}