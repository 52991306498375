@import '../../../../../variables';

.line-words-component {
  //height: 100%;
  position: relative;

  #canvas-line-words {
    //height: 100%;
    //width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    box-sizing: border-box;
  }

  .words-list {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &.words-template-1 {
      .item {

        &:first-child {
          top: 13%;
          left: 10%;
        }

        &:nth-child(2) {
          left: 56%;
          top: 24%;
        }

        &:nth-child(3) {
          left: 32%;
          top: 53%;
        }
      }
    }

    &.words-template-2 {
      .item {

        &:first-child {
          top: 13%;
          left: 56%;
        }

        &:nth-child(2) {
          left: 10%;
          top: 24%;
        }

        &:nth-child(3) {
          left: 39%;
          top: 53%;
        }
      }
    }

    &.words-template-3 {
      .item {

        &:first-child {
          top: 53%;
          left: 10%;
        }

        &:nth-child(2) {
          left: 38%;
          top: 13%;
        }

        &:nth-child(3) {
          left: 55%;
          top: 49%;
        }
      }
    }

    &.words-template-4 {
      .item {

        &:first-child {
          top: 32%;
          left: 10%;
        }

        &:nth-child(2) {
          left: 51%;
          top: 13%;
        }

        &:nth-child(3) {
          left: 55%;
          top: 55%;
        }
      }
    }

    &.words-template-5 {
      .item {

        &:first-child {
          top: 61%;
          left: 52%;
        }

        &:nth-child(2) {
          left: 51%;
          top: 13%;
        }

        &:nth-child(3) {
          left: 8%;
          top: 46%;
        }
      }
    }

    .item {
      position: absolute;
      width: 34.7%;
      padding-bottom: 34.7%;
      text-align: center;
      border-radius: 100%;
      background-image: url("img/bg-words.png");
      background-size: 100%;
      background-repeat: no-repeat;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

      &.active {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          height: 90%;
          width: 90%;
          border: 2px solid $color-blue-3;
          border-radius: 100%;
        }
      }
      
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        user-select: none;
      }
    }
  }
}