@import '../../variables.scss';

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 14px;
  background: linear-gradient(180deg, #CCCCCC 0%, rgba(196, 196, 196, 0) 100%);

  //&:before,
  //&:after {
  //  content: '';
  //  display: block;
  //}

  .progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, #CDC8C8 100%);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
    border-radius: 14px;
    transition: width .1s ease-in-out;

    &::after {
      content: '';
      position: absolute;
      top: 4px;
      left: 3px;
      right: 4px;
      height: 2px;
      border-radius: 5px;
      background-color: rgba($color-black, .1);
    }
  }

  .step {
    width: 6px;
    height: 6px;
    background-color: $color-white;
    border-radius: 100%;
    z-index: 2;

    &.correct {
      background-color: $color-green-6;
    }

    &.incorrect {
      background-color: $color-purple-9;
    }
  }
}