@import '../../../../../variables';

.swipe-it-component {

  .cards-list {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    //height: 100%;
    will-change: transform;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 50px;

    .card-item {
      box-sizing: border-box;
      background-color: white;
      background-size: auto 85%;
      background-repeat: no-repeat;
      background-position: center center;
      width: 220px;
      padding-bottom: 232px;
      will-change: transform;
      border-radius: 10px;
      box-shadow: 0 12.5px 40px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
      opacity: 1;
    }
  }

  .swipe-controls {

    .wrapper-control-left,
    .wrapper-control-right {
      position: absolute;
      bottom: 10%;
      background: linear-gradient(180deg, rgba(30, 29, 82, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
      background-blend-mode: multiply;

      img {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      }
    }

    .wrapper-control-left {
      left: 0;
      padding: 20px 30px 20px 15px;
      border-radius: 0 45px 45px 0;
    }

    .wrapper-control-right {
      right: 0;
      padding: 20px 15px 20px 30px;
      border-radius: 45px 0 0 45px;
    }
  }

  .icon-control-left,
  .icon-control-right {
    position: fixed;
    top: 46%;
    transform: translateY(-50%);
    width: 23.493%;
    max-width: 74px;
    //pointer-events: none;
  }

  .icon-control-left {
    left: 0;
  }

  .icon-control-right {
    right: 0;
  }
}

@media (max-height: 750px) {
  .swipe-it-component {
    .cards-list {
      .card-item {
        width: 176px;
        padding-bottom: 186px;
        background-size: auto 84%;
      }
    }
  }
}

@media (max-height: 700px) {
  .swipe-it-component {
    .icon-control-left,
    .icon-control-right {
      max-width: 65px;
      top: 45%;
    }

    .swipe-controls {

      .wrapper-control-left img,
      .wrapper-control-right img {
        max-width: 25px;
      }

      .wrapper-control-left {
        padding: 16px 24px 16px 12px;
      }

      .wrapper-control-right {
        padding: 16px 12px 16px 24px;
      }
    }

    .cards-list {
      .card-item {
        width: 155px;
        padding-bottom: 163px;
        background-size: auto 81%;
      }
    }
  }
}

@media (max-height: 600px) {
  .swipe-it-component {

    .icon-control-left,
    .icon-control-right {
      max-width: 60px;
    }
  }
}