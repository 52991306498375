@import "../../variables";

.App.achievements .layout-inner {
  background: $gradient-bg;
}

.App.achievements .main {
  overflow: auto;
}

.achievments-component {
  padding-top: 9px;

  .tabs-nav {
    li {
      &.selected-tab {
        .box-wave {
          background: $color-green-4;
        }
      }
    }
  }

  .achievements-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .achievement {
      width: 50%;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      margin-bottom: 20px;
      box-sizing: border-box;

      .box-shadow {
        height: 100%;
        padding: 17px;
        box-sizing: border-box;
      }

      img {
        width: 100px;
        margin-bottom: 10px;
      }

      .title-achievement {
        font-size: 20px;
        line-height: 33px;
        margin-bottom: 10px;
      }

      .date-achievement {
        font-size: 14px;
        line-height: 23px;
      }
    }
  }
}