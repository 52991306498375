@import '../../variables';

.App.map {
  background-color: $color-green-1;

  .layout-inner {
    background-image: none;
  }

  .main {
    margin-top: -10%;
    overflow: auto;
  }
}

.map-component {
  position: relative;

  &.scrolled-bottom {
    &::after {
      content: none;
    }

    .button-map {
      bottom: 30%;
    }
  }

  &::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(0deg, $color-gray-2 18.75%, rgba($color-gray-2, 0) 100%);
  }

  & > img {
    display: block;
  }

  .button-map {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 2;
    transition: bottom 0.3s ease-in-out;

    .img {
      position: absolute;
      top: 8px;
      right: 13px;
    }

    p {
      position: absolute;
      font-weight: 800;
      min-width: 125px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.8) linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 54.17%, #DEECEC 100%);
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));
      border-radius: 8px;
      top: 93px;
      right: 4px;
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }

  .step {
    position: absolute;
    width: 14%;
    height: 0;
    padding-bottom: 14%;
    background-image: url('./img/bg-step-current-1.png');
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0 19px 22px -16px rgba(0, 0, 0, 0.75);
    border-radius: 36%;

    .step-inner {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
    }

    span {
      font-size: 26px;
      line-height: 1;
      color: $color-white;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.locked {
      background-image: url('./img/bg-step-done-1.png');
    }

    &.info {
      background-image: url('./img/bg-step-info-1.png');
    }

    &.unlocked {
      background-image: url('./img/bg-step-future-1.png');
    }
  }

  //.step-1 {
  //  top: 8%;
  //  left: 6%;
  //}
  //
  //.step-2 {
  //  top: 16%;
  //  left: 14%;
  //}
  //
  //.step-3 {
  //  top: 18%;
  //  left: 37%;
  //}
  //
  //.step-4 {
  //  top: 23%;
  //  left: 56%;
  //}
  //
  //.step-info-1 {
  //  top: 21%;
  //  left: 75%;
  //}
  //
  //.step-5 {
  //  top: 30%;
  //  left: 75%;
  //}
  //
  //.step-6 {
  //  top: 42%;
  //  left: 82%;
  //}
  //
  //.step-info-2 {
  //  top: 51%;
  //  left: 66%;
  //}
  //
  //.step-7 {
  //  top: 60%;
  //  left: 61%;
  //}
  //
  //.step-8 {
  //  top: 65%;
  //  left: 39%;
  //}
  //
  //.step-9 {
  //  top: 72%;
  //  left: 19%;
  //}
  //
  //.step-10 {
  //  top: 82%;
  //  left: 8%;
  //}
  //
  //.step-11 {
  //  top: 92%;
  //  left: 16%;
  //}
}

@media (max-height: 750px) {
  .map-component {
    .map-btn-bg {
      height: 165px;
    }

    .button-map {
      p {
        min-width: 110px;
        height: 37px;
        top: 83px;
      }
    }
  }
}

@media (max-height: 570px) {
  .map-component {
    .map-btn-bg {
      height: 150px;
    }
  }
}