@import '../../variables';

html:lang(ar) {
	.menu-list,
	.profile-component,
	.tickets-tab,
	.prizes-component,
	.skills-component,
	.tutorials-list,
	.slick-slide,
	.row-phone,
	.modal-message,
	.results-list,
	.instructions-message,
	.result-game-component .total,
	.points,
	.auth-component .form-container .form-field input,
	.date-unlock,
	.leaderboard-list,
	.footer {
		direction: rtl;
		text-align: right;
	}

	.result-game-component .results-list .item .xp p {
		direction: ltr;
		text-align: right;
	}

	.auth-component .form-container .row-phone .form-field input {
		direction: ltr;
		text-align: right;
	}

	.result-game-component .total {
		display: flex;
		justify-content: space-between;
	}

	.instructions-game {
		display: flex;
		direction: rtl;
		text-align: right;
		justify-content: end;
	}

	.validation-message {
		display: flex;
		justify-content: flex-end;
		direction: ltr;
		text-align: right;
	}

	.result-game-component .total .xp {
		direction: ltr;
		margin-left: inherit;
		text-align: right;
	}

	.home-component .warning-message {
		justify-content: end;
		direction: rtl;
	}

	.box-skill .levels > img {
		-webkit-transform: scaleX(-1);
		transform: scaleX(-1);
	}

	.skip {
		left: 0px;
		right: auto;
	}

	.auth-component .form-container .row-phone .form-field input,
	.auth-component .form-container .form-field input {
		text-align: right;
	}

	.auth-component .form-container .row-phone .prefix {
		padding-left: 10px;
		padding-right: 0px;
	}

	.signin-component .form-container .form-title,
	.auth-component .form-container .submit-btn,
	.signin-component .form-container .forgot-pass-link,
	.signin-component .no-account p,
	.signin-component .no-account .btns a,
	.auth-component .page-title h3,
	.small-form-component .instructions-auth,
	.signup-component .phone-form .terms {
		direction: rtl;
	}

	.footer .prize-img {
		margin-right: 0;
		margin-left: 14px;
	}
}
