@import '../../variables.scss';

.input-file {
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3));

  input {
    visibility: hidden;
    height: 0;
    position: absolute;
  }

  label {
    padding-top: 10px;
    padding-bottom: 10px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 54.17%, #DEECEC 100%);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $color-blue-2;
  }
}