@import '../../variables.scss';

.ReactModal__Overlay {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6) !important;
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity .1s ease-in-out;
}

.ReactModal__Content {
  transform: scale(0) translateY(-50%);
}

.ReactModal__Content--after-open {
  transform: scale(1) translateY(-50%);
  transition: transform .1s ease-out;
}

.modal-general {
  width: 100%;
  position: absolute;
  top: 50%;
  min-height: 62vh;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;

  .modal-wrapper {
    min-width: 275px;
    margin: 0 auto;
    background: rgba($color-white, .9);
  }

  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    box-sizing: border-box;

    &.no-close {
      padding-top: 47px;
    }
  }

  .close-modal {
    margin: 7px 7px 10px auto;
    display: block;
  }

  .modal-info {
    padding-left: 17px;
    padding-right: 17px;
  }

  .modal-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 20px;
  }

  .level {
    margin-bottom: 20px;

    img {
      display: block;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .buttons-group {
    display: flex;
    justify-content: center;
    margin-left: -7px;
    margin-right: -7px;
    margin-top: auto;
    padding-top: 15px;

    .btn-modal {
      width: 150px;
      max-width: 44%;
      height: 46px;
      line-height: 48px;
      font-weight: 800;
      font-size: 16px;
      color: #20ABDB;
      background: linear-gradient(180deg, $color-white 0%, rgba($color-white, 0) 54.17%, #DEECEC 100%);
      background-color: #ffffff;
      filter: drop-shadow(0px 10px 8px #B4D3DD);
      border-radius: 8px;
      display: block;
      text-align: center;

      &:nth-child(2) {
        margin-left: 15px;
      }

      &.btn-secondary {
        background: linear-gradient(180deg, $color-blue-9 0%, $color-blue-11 100%);
        color: $color-white;
      }
    }
  }
}

@media (max-width: 350px) {
  .modal-general {
    min-width: auto;

    .buttons-group {
      margin-left: 0;
      margin-right: 0;
      display: block;

      .btn-modal{
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;

        &:nth-child(2) {
          margin-left: auto;
          margin-top: 15px;
        }
      }
    }
  }
}