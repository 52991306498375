@import "../../../../../variables";

.recover-component {
  .recover-info {
    .instructions-auth {
      margin-bottom: 0;
    }

    .phone-no {
      font-weight: 800;
      font-size: 20px;
      line-height: 32px;
      margin-top: -7px;
      margin-bottom: 10px;
    }
  }
}