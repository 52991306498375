@import '../../variables.scss';

.modal-general.modal-languages {
  top: 36%;
  min-height: 41vh;

  .modal-wrapper {
    width: 100%;
  }

  .modal-content {
    padding-bottom: 27px;
  }

  .modal-info {
    padding: 50px 27px 0;



    .custom-radio {
      label {
        display: flex;
        align-items: center;

        img {
          margin-right: 20px;
        }
      }

      input[type="radio"] {
        &:checked,
        &:not(:checked) {
          position: absolute;
          left: -9999px;

          & + label {
            font-size: 26px;
            line-height: 26px;
            font-weight: 500;
            color: $color-text;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-bottom: 30px;
            cursor: pointer;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto 0;
              width: 24px;
              height: 24px;
              border-radius: 100%;
              border: 2px solid $color-gray-4;
              background-color: $color-white;
            }

            &::after {
              content: '';
              width: 16px;
              height: 16px;
              background: $color-blue-3;
              position: absolute;
              right: 6px;
              top: 0;
              bottom: 0;
              margin: auto;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
            }
          }
        }

        &:not(:checked) + label::after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }

        &:checked + label {
          &::before {
            border-color: $color-blue-3;
          }
          &::after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
        }
      }
    }
  }
}